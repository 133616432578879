import React from "react"
import Helmet from 'react-helmet'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ReactMarkdown from 'react-markdown'
import { formatDateEu, helmetMeta } from '../utils/utils'


export default function BlogPost({ data, pageContext }) {
  return (
    <Layout>
        <Helmet title={'Gnucoop - '+data.strapiArticle.Title}
    meta={helmetMeta({
      title:       'Gnucoop - '+data.strapiArticle.Title,
      description: data.strapiArticle.Excerpt,
      fixedImage:  data.strapiArticle.FeatureImage.localFile.children[0].fixed.src,
    })}/>
    <Navbar />
    <PageBanner
        pageTitle={data.strapiArticle.Title}
        homePageText="Blog"
        homePageUrl="/blog"
        activePageText={data.strapiArticle.Title}
    />
    <section className="blog-details-area ptb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-12">
                    <div className="blog-details-desc">
                            <div className="article-image">
                            <img src={data.strapiArticle.FeatureImage.localFile.children[0].fixed.src}></img>
                            </div>
                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <i className='bx bx-folder-open'></i>
                                            <span>CATEGORIA </span>
                                            GNUCOOP
                                        </li>
                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>Pubblicato il</span>
                                            {formatDateEu(data.strapiArticle.PublishDate)}
                                        </li>
                                    </ul>
                                </div>
                                <br></br>
                                    <ReactMarkdown  children={data.strapiArticle.Content} />
                                </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="widget-area">
          <div className="widget widget_tracer_posts_thumb">
              <h3 className="widget-title">Articoli in evidenza</h3>
              {data.allStrapiArticle.edges.map(article => (
              <article className="item">
                  <Link to={'/'+article.node.Slug} className="thumb">
                      <img className="fullimage cover bg1" role="img" src={article.node.FeatureImage.localFile.children[0].fixed.src}></img>
                  </Link>
                  <div className="info">
                      <span>{article.node.PublishDate}</span>
                      <h4 className="title usmall">
                          <Link to={'/'+article.node.Slug}>
                          {article.node.Title}
                          </Link>
                      </h4>
                  </div>

                  <div className="clear"></div>
              </article>
              ))}
          </div>
          <div className="widget widget_tag_cloud">
              <h3 className="widget-title">Popular Tags</h3>

              <div className="tagcloud">
              {data.strapiArticle.tags.map(t => (
                                                <span><a href={'/tag/'+t.tag}>{t.tag}</a></span>
                                             ))}
              </div>
          </div>
      </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</Layout>
  )
}

export const query = graphql`
  query ArticleTemplate($Slug: String) {
    strapiArticle(Slug: {eq: $Slug}) {
      Title
      Content
      Slug
      PublishDate
      FeatureImage {
        localFile {
            children {
              ... on ImageSharp {
                fixed (width: 800) {
                  src
                }
              }
            }
        }
      }
      Excerpt
      tags {
              tag
            }
    }
    allStrapiArticle (
        sort: {fields: [PublishDate], order: DESC}
        filter: {tags: {elemMatch: {tag: {eq: "homepage"}}}}
      ) {
        edges {
          node {
            id
            Title
            Slug
            PublishDate
            tags {
              tag
            }
            FeatureImage {
              localFile {
                children {
                  ... on ImageSharp {
                    fixed(width: 150) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
 
`